body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1E293B;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

@font-face {
  font-family: 'ObjectiveMediumItalic';
  src: local('Rajdhani'), url(./fonts/Objective-Medium-Italic.ttf) format('truetype');
}

.objectiveMediumItalic{
  font-family: ObjectiveMediumItalic
}

.larghMin {
  min-width: 79.5rem;
}
.triangoloDestro {
  width: 0;
  height: 0;
  border-top: 0.5rem solid transparent;
  border-left: 1rem solid white;
  border-bottom: 0.5rem solid transparent;
}

.flip-horizontal {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

