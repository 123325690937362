/* Tab content - closed */
  .tab-content {
  max-height: 0;
  -webkit-transition: max-height .35s;
  -o-transition: max-height .35s;
  transition: max-height .35s;
  }
  /* :checked - resize to full height */
  .tab input:checked ~ .tab-content {
  max-height: 100vh;
  }
  /* Label formatting when open */
  .tab input:checked + label{
  /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
  font-size: 1.25rem; /*.text-xl*/
  padding: 1.25rem; /*.p-5*/
  border-left-width: 2px; /*.border-l-2*/
  border-color: #6574cd; /*.border-indigo*/
  background-color: #f8fafc; /*.bg-gray-100 */
  color: #6574cd; /*.text-indigo*/
  }
  .tabModifica {
    /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
    font-size: 1.25rem; /*.text-xl*/
    padding: 1.25rem; /*.p-5*/
    border-left-width: 2px; /*.border-l-2*/
    border-color: #6574cd; /*.border-indigo*/
    background-color: #f8fafc; /*.bg-gray-100 */
    color: #6574cd; /*.text-indigo*/
    }
  /* Icon */
  .tab label::after {
  float:right;
  right: 0;
  top: 0;
  display: block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  font-size: 1.25rem;
  text-align: center;
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
  }
  /* Icon formatting - closed */
  .tab input[type=checkbox] + label::after {
    content: "\25BE";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
    background-color: #6574cd; /*.bg-indigo*/
    color: #f8fafc; /*.text-grey-lightest*/
  }
  .tab input[type=radio] + label::after {
  content: "\25BE";
  font-weight:bold; /*.font-bold*/
  border-width: 1px; /*.border*/
  border-radius: 9999px; /*.rounded-full */
  border-color: #b8c2cc; /*.border-grey*/
  }
  /* Icon formatting - open */
  .tab input[type=checkbox]:checked + label::after {
    transform: rotateX(180deg);
    background-color: #6574cd; /*.bg-indigo*/
    color: #f8fafc; /*.text-grey-lightest*/
  }
  .tab input[type=radio]:checked + label::after {
  transform: rotateX(180deg);
  background-color: #6574cd; /*.bg-indigo*/
  color: #f8fafc; /*.text-grey-lightest*/
  }

  @font-face {
    font-family: 'ObjectiveRegular';
    src: local('Objective'), url(./fonts/Objective-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'ObjectiveItalic';
    src: local('Objective'), url(./fonts/Objective-Italic.ttf) format('truetype');
  }

  @font-face {
    font-family: 'ObjectiveMedium';
    src: local('Objective'), url(./fonts/Objective-Medium.ttf) format('truetype');
  }

  @font-face {
    font-family: 'ObjectiveBold';
    src: local('Objective'), url(./fonts/Objective-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'ObjectiveBoldItalic';
    src: local('Objective'), url(./fonts/Objective-Bold-Italic.ttf) format('truetype');
  }
  
  .ObjectiveItalic{
    font-family: ObjectiveItalic
  }
  .ObjectiveBoldItalic{
    font-family: ObjectiveBoldItalic
  }
  .ObjectiveRegular{
    font-family: ObjectiveRegular
  }
  .ObjectiveMedium{
    font-family: ObjectiveMedium
  }
  .ObjectiveBold{
    font-family: ObjectiveBold
  }

  .flip {  
    text-align: center;
    perspective: 600px;  
  }
  .flip-content {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  .flip:hover .flip-content {
    transform: rotateY(180deg);
  }
  .flip-front, .flip-back {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;  
  }
  .flip-back {
    transform: rotateY(180deg);
  }
